import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';

const UbloxPage = () => {
  const data = useStaticQuery(graphql`
    query {
      ustart: file(relativePath: { eq: "u-start.png" }) {
        ...projectImage
      }
      xplr1: file(relativePath: { eq: "ublox-xplr-1.png" }) {
        ...projectImage
      }
      xplr2: file(relativePath: { eq: "ublox-xplr-2.png" }) {
        ...projectImage
      }
      xplr3: file(relativePath: { eq: "ublox-xplr-3.png" }) {
        ...projectImage
      }
      xplr4: file(relativePath: { eq: "ublox-xplr-4.png" }) {
        ...projectImage
      }
      xplr5: file(relativePath: { eq: "ublox-xplr-5.png" }) {
        ...projectImage
      }
      ucenter: file(relativePath: { eq: "ublox-ucenter.png" }) {
        ...projectImage
      }
    }
  `);

  return (
    <Layout>
      <div id="main">
        <div className="inner">
          <h1>u-blox – Explorer Kit</h1>
          <ul>
            <li>
              <strong>Collaborated with UX designers</strong>, marketeers and
              engineers to create a novel application for testing GPS chipsets
            </li>
            <li>
              Implemented the application using <strong>Electron</strong> and
              calling C++ interfaces, Angular, Cypress, Jest
            </li>
            <li>
              Introduced a design system based on <strong>WebComponents</strong>
              , using Stencil and <strong>Storybook</strong>
            </li>
            <li>
              Created a proof-of-concept using <strong>gRPC / protobuf</strong>{' '}
              and performance comparison
            </li>
          </ul>

          <p>
            YouTube tutorial:{' '}
            <a
              href="https://www.youtube.com/watch?v=2-RokceqaVE"
              target="_blank"
              rel="noreferrer"
            >
              How to get started with u-blox XPLR-M9
            </a>
          </p>
          <div className="box alt">
            <div className="row gtr-uniform">
              <div className="col-4">
                <span className="image fit">
                  <Img fluid={data.xplr1.childImageSharp.fluid} alt="u-start" />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <Img
                    fluid={data.xplr2.childImageSharp.fluid}
                    alt="ublox-xplr"
                  />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <Img
                    fluid={data.xplr3.childImageSharp.fluid}
                    alt="ublox-xplr"
                  />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <Img
                    fluid={data.xplr4.childImageSharp.fluid}
                    alt="ublox-xplr"
                  />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <Img
                    fluid={data.xplr5.childImageSharp.fluid}
                    alt="ublox-xplr"
                  />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <Img
                    fluid={data.ucenter.childImageSharp.fluid}
                    alt="ublox-xplr"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UbloxPage;
